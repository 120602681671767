import ApiService from "./api.service";

export const POST_SUBSCRIPTION = "postSubscription";

const actions = {
  [POST_SUBSCRIPTION](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("subscription", data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

export default {
  actions
}
