import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth.module";
import photo from "./photo.module";
import visitorMessage from "./visitor-message.module";
import subscription from "./subscription.module";
import cv from "./cv.module";
import contractpal from "./contractpal.module";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    photo,
    visitorMessage,
    subscription,
    cv,
    contractpal
  }
})
