import ApiService from "./api.service";
import config from "@/ConfigProvider";
const GETHIRED_API = config.value("GETHIRED_API");
const CV_BOT_API = config.value("CV_BOT_API");

export const GET_CV_TOKEN = "getCvToken";
export const SAVE_CV_TOKEN = "saveCvToken";
export const GENERATE_CV_DATA = "generateCvData";

const actions = {
  [GET_CV_TOKEN](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${GETHIRED_API}/cvtoken/${params?.cvtoken}/custom`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SAVE_CV_TOKEN](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `${GETHIRED_API}/cvtoken/${params?.cvtoken}/custom`,
        params.payload
      )
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GENERATE_CV_DATA](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${CV_BOT_API}/${params.endpoint}`, params.payload)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  actions,
};
