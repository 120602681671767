// const customIcons = {
//   iconSetting: {
//     path: "@/assets/icon/icon-setting.svg"
//   }
// }

export default {
  colors: {
    main: "#29aafe",
    buttonNavbar: "#ffc35a",
    hoverButtonNavbar: "#325c8c",
    fontMain: "#4a4a4a",
    breadcrumbNotActive: "#333333",
    breadcrumbActive: "#777",
    bgMain: "#e5e5e5",
    dividerColor: "#d3d3d3",
    borderBox: "#c7c7c7",
    premiumColor: "#F8A541",
    activeMenu: "#16ABF8",
    activeMenuOther: "##a4e0ff",
    activeMenuPagination: "rgba(16,171,248,0.1)",
    bgSocialBadge: "#f4f4f4",
    facebookColor: "#4267B2",
    twitterColor: "#00ACEE",
    instagramColor: "#CD486B",
    whatsappColor: "#128C7E",
    buttonSecondary: "#EFEFEF",
    buttonSecondaryColor: "#555555",
    breadcrumbNotActive2: "#C7C7C7",
    breadcrumbActive2: "#4A4A4A",
    fontInformation: "#888888",
    fontCountService: "#858686",
    fontCopyEmail: "#A4A4A4",
    bgButtonSecondary3: "rgba(74,74,74,0.05)",
    buttonSecondary3: "rgba(74,74,74,0.4)",
    comingSoon: "rgba(22, 171, 248, 0.5)",
    videoNote: "rgba(255, 194, 106, 0.2)"
  },
  textStyles: {
    body: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
  // icons: {
  //   extend: {
  //     ...customIcons
  //   }
  // }
}