import axios from "axios";
import JwtService from "./jwt.service";
import config from "@/ConfigProvider";
// import store from "./index";
// import { GET_JWT } from "./auth.module"

// const checkJWT = async () => {
//   if (!store.getters.isAuthenticated) {
//     await store.dispatch(GET_JWT);
//   }
// }

// import axiosCookieJarSupport from 'axios-cookiejar-support';
// import tough from 'tough-cookie';

const ApiService = {
  init() {
    axios.defaults.baseURL = config.value("API_URL");
  },

  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return axios.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  async get(resource, params) {
    if (params && params.session) {
      // axiosCookieJarSupport(axios);
      // const cookieJar = new tough.CookieJar();
      return axios.get(`${resource}`, { 
        // headers: {
        //   Cookie: document.cookie
        // },
        // jar: cookieJar,
        withCredentials: true
       }).catch(error => {
        throw new Error(`ApiService ${error}`);
      });
    } else {
      return axios.get(`${resource}`, { params }).catch(error => {
        throw new Error(`ApiService ${error}`);
      });
    }
  },

  post(resource, params) {
    return axios.post(`${resource}`, params);
  },

  upload(resource, params) {
    let formData = new FormData();
    formData.append("file", params.data);
    delete axios.defaults.headers.common["Authorization"];
    return axios.post(`${resource}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      responseType: "arraybuffer"
    });
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return axios.delete(resource).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  }
};

export default ApiService;
