import { render, staticRenderFns } from "./App.vue?vue&type=template&id=30a7e56c&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=30a7e56c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a7e56c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CReset: require('@chakra-ui/vue').CReset, CThemeProvider: require('@chakra-ui/vue').CThemeProvider})
