import ApiService from "./api.service";

export const POST_BG_PHOTO = "postBGPhoto";

const actions = {
  [POST_BG_PHOTO](context, params) {
    return new Promise((resolve, reject) => {
      Object.assign(params, { responseType: 'arraybuffer' })
      ApiService.upload("https://rembg.gethired.id/bg/remove", params)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
}

export default {
  // state,
  actions,
  // mutations,
  // getters
};