import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/about",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/",
    redirect: "/",
    component: () => import("@/views/layout/LayoutDashboard.vue"),
    children: [
      // {
      //   path: "/dashboard/overview",
      //   name: "Dashboard Overview",
      //   component: () => import("@/views/pages/dashboard/Overview.vue")
      // },
      {
        path: "/",
        name: "Dashboard Web CV",
        component: () => import("@/views/pages/dashboard/WebCV.vue")
      },
      {
        path: "/choose-web-cv",
        name: "Choose Web CV",
        component: () => import("@/views/pages/dashboard/ChooseWebCV.vue")
      },
      {
        path: "/pesan-pengunjung",
        name: "Dashboard Pesan Pengunjung",
        component: () => import("@/views/pages/dashboard/PesanPengunjung.vue")
      },
      {
        path: "/detail-pesan",
        name: "Detail Pesan Pengunjung",
        component: () => import("@/views/pages/dashboard/DetailPesanPengunjung.vue")
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/views/layout/LayoutNonSidebar.vue"),
    children: [
      {
        path: "/select-web-cv",
        name: "Choose Web CV Wizard",
        component: () => import("@/views/pages/dashboard/ChooseWebCVWizard.vue")
      },
      {
        path: "/profil-cv",
        name: "Dashboard Desain CV",
        component: () => import("@/views/pages/dashboard/DesainCV.vue")
      },
      {
        path: "/upload-photo",
        name: "Dashboard Upload Photo",
        component: () => import("@/views/pages/dashboard/UploadPhoto.vue")
      },
      {
        path: "/video-cv",
        name: "Video CV",
        component: () => import("@/views/pages/dashboard/VideoCV.vue")
      },
      {
        path: "/config-services",
        name: "Config Services",
        component: () => import("@/views/pages/dashboard/ConfigServices.vue")
      },
      {
        path: "/config-complete",
        name: "Config Complete",
        component: () => import("@/views/pages/dashboard/ConfigComplete.vue")
      },
    ]
  },
  {
    path: "/contractpal",
    name: "Custom your CV",
    component: () => import ("@/views/pages/Contractpal.vue")
  },
  {
    path: "/contractpal-result",
    name: "Custom your CV",
    component: () => import ("@/views/pages/ContractpalResult.vue")
  },
  {
    path: "/subscription/:package",
    name: "Subscription",
    component: () => import ("@/views/pages/Subscription.vue")
  },
  {
    path: "/subscription/:package/:voucher",
    name: "Subscription with Voucher",
    component: () => import ("@/views/pages/Subscription.vue")
  },
  {
    path: "/custom-cv/:token",
    name: "Custom your CV",
    component: () => import ("@/views/pages/CustomCv.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
