import ApiService from "./api.service";
import JwtService from "./jwt.service";
import config from "@/ConfigProvider";
// import moment from "moment";
// import _ from "lodash";
import findIndex from "lodash.findindex";
import { paramCase } from "change-case";

export const GET_JWT = "getJWT";
export const GET_USER = "getUser";
export const VERIFY_AUTH = "verifyAuth";
export const PURGE_AUTH = "purgeAuth";
export const UPDATE_USER_EMPLOYEE = "updateUserEmployee";
export const UPDATE_VIDEO = "updateVideo";
export const UPDATE_SERVICE = "updateService";
export const GET_SERVICE = "getService";
export const UPDATE_CV = "updateCV";

export const SET_JWT = "setJWT";
export const SET_USER = "setUser";
export const SET_SERVICE = "setService";

const socialView = [
  {
    source: "facebook",
    sourceDisplay: "Facebook",
    color: "facebookColor"
  },
  {
    source: "twitter",
    sourceDisplay: "Twitter",
    color: "twitterColor"
  },
  {
    source: "instagram",
    sourceDisplay: "Instagram",
    color: "instagramColor"
  },
  {
    source: "whatsapp",
    sourceDisplay: "Whatsapp",
    color: "whatsappColor"
  },
  {
    source: "other",
    sourceDisplay: "Other",
    color: "fontMain"
  },
]

const webCVlist = [
  {
    name: "Kotagede",
    slug: "kotagede",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Wirobrajan",
    slug: "wirobrajan",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Kalasan",
    slug: "kalasan",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Godean",
    slug: "godean",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Danurejan",
    slug: "danurejan",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Gamping",
    slug: "gamping",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Wonosari",
    slug: "wonosari",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Patuk",
    slug: "patuk",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Gedangsari",
    slug: "gedangsari",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Yogyakarta",
    slug: "yogyakarta",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Ngampilan",
    slug: "ngampilan",
    is_coming_soon: false,
    is_mentor: false,
  },
  {
    name: "Dark",
    slug: "mentor-dark",
    is_coming_soon: false,
    is_mentor: true,
  },
  {
    name: "Light",
    slug: "mentor-light",
    is_coming_soon: false,
    is_mentor: true,
  },
];

const cvList = [
  { name: "Fresh - BLACK", slug: "fresh-black", id: "11.1" },
  { name: "Fresh - TOSCA", slug: "fresh-tosca", id: "11.2" },
  { name: "Fresh - ORANGE", slug: "fresh-orange", id: "11.3" },
  { name: "Fresh - BLUE", slug: "fresh-blue", id: "11.4" },
  { name: "Awesome - BLACK", slug: "awesome-black", id: "12.1" },
  { name: "Awesome - GREEN", slug: "awesome-green", id: "12.2" },
  { name: "Awesome - BLUE", slug: "awesome-blue", id: "12.3" },
  { name: "Awesome - RED", slug: "awesome-red", id: "12.4" },
  { name: "Simple - BLACK", slug: "simple-black", id: "13.1" },
  { name: "Simple - BLUE", slug: "simple-blue", id: "13.2" },
  { name: "Simple - RED", slug: "simple-red", id: "13.3" },
  { name: "Simple - GREEN", slug: "simple-green", id: "13.4" },
]

const state = {
  isAuthenticated: !!JwtService.getToken(),
  // isAuthenticated: false,
  user: {},
  webCVlist,
  cvList,
  service: [],
  sessionId: JwtService.getSession()
}

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  currentUser(state) {
    return state.user
  },
  currentWebCVList(state) {
    return state.webCVlist
  },
  currentCVList(state) {
    return state.cvList
  },
  currentService(state) {
    return state.service
  },
  currentSession(state) {
    return state.sessionId
  }
}

const actions = {
  [GET_JWT](context) {
    return new Promise((resolve, reject) => {
      if (!context.getters.currentSession) {
        context.commit(PURGE_AUTH);

        window.location.href = config.value("FE_URL")+"/employee/profile";
        return
      }
      ApiService.get(config.value("FE_URL")+"/employee/jwt", { session: context.getters.currentSession })
        .then(async ({ data }) => {
          context.commit(SET_JWT, data.jwt);
          setTimeout(() => {
            ApiService.setHeader();
            return;
          }, 100);
          resolve(data);
        })
        .catch((err) => {
          context.commit(PURGE_AUTH);
          window.location.href = config.value("FE_URL")+"/employee/profile";
          reject(err);
        });
    });

    // use this code for local development, and comment code above
    // try {
    //   const token = config.value("HARDCODE_TOKEN");
    //   context.commit(SET_JWT, token);
    //   setTimeout(() => {
    //     ApiService.setHeader();
    //     return;
    //   }, 100);
    // } catch (err) {
    //   context.commit(PURGE_AUTH);
    // }
  },
  async [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    } else {
      await context.dispatch(GET_JWT);
    }
  },
  [GET_USER](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("user_employee")
        .then(async ({ data }) => {
          context.commit(SET_USER, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_USER_EMPLOYEE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.patch("/user_employee/" + params.id, params.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [UPDATE_VIDEO](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/video?user_id=" + params.id, params.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [UPDATE_SERVICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/services?emp_id=" + params.id, params.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  [GET_SERVICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("services", {
        emp_id: params
      })
        .then(async ({ data }) => {
          context.commit(SET_SERVICE, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_CV](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("https://api.gethired.id/v1/cv", {
        template_id: params
      })
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}

const mutations = {
  [SET_JWT](state, data) {
    if (data) {
      state.isAuthenticated = true;
      JwtService.saveToken(data);
    }
    return;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.sessionId = "";
    JwtService.destroyToken();
    JwtService.destroyUser();
  },
  [SET_USER](state, data) {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    if (data) {
      const nameArr = data.emp_full_name.split(" ");
      data.shortName = nameArr[0];
    }
    // if (data.premium_end) data.premium_end_str = moment(data.premium_end).locale("id").format("DD MMMM YYYY")
    if (data.premium_end) data.premium_end_str = new Intl.DateTimeFormat("id", options).format(new Date(data.premium_end));
    let used = [];
    for (const d of socialView) {
      const index = findIndex(data.view, ["ulv_source", d.source]);
      if (index != -1) {
        d.count = data.view[index].count
      } else {
        d.count = 0
      }
      used.push(d)
    }
    data.view_addition = used

    // change case cv design
    data.web_cv_design_converted = data.web_cv_design ?  paramCase(data.web_cv_design) : "kotagede";
    if (data.is_mentor && !data.web_cv_design) data.web_cv_design_converted = "mentor-dark";
    data.cv_design_converted = data.cv_design ? data.cv_design : "11.1";

    state.user = data;
    // JwtService.saveUser(JSON.stringify(data));
    return;
  },
  [SET_SERVICE](state, data) {
    state.service = data;
    return;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};