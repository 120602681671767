import ApiService from "./api.service";
// import moment from "moment";

export const GET_VISITOR_MESSAGES = "getVisitorMessages";

export const SET_VISITOR_MESSAGES = "setVisitorMessages";

const state = {
  visitorMessages: {},
}

const getters = {
  currentVisitorMessages(state) {
    return state.visitorMessages;
  },
}

const actions = {
  [GET_VISITOR_MESSAGES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("visitor-messages", params)
        .then(async ({ data }) => {
          Object.assign(data.meta, { page: params.page })
          await context.commit(SET_VISITOR_MESSAGES, data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
}

const mutations={
  [SET_VISITOR_MESSAGES](state, data) {
    // convert data and add numbering
    let i=0;
    let limit = 10;
    // moment.locale('id');
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    for (const d of data.data) {
      // data.data[i].created_at_str = moment(d.created_at).format("DD MMMM YYYY, HH:mm")
      data.data[i].created_at_str = new Intl.DateTimeFormat("id", options).format(new Date(d.created_at));
      data.data[i].orderNumber = (i+1) + ((data.meta.page-1)*limit)
      i += 1;
    }

    // handle total page, and etc
    const start = 1 + ((data.meta.page - 1) * limit)
    const maxData = data.meta.page * limit;
    let last_row = 0;
    if (data.meta.total > maxData) {
      last_row = maxData;
    } else {
      last_row = (data.meta.total % limit) + ((data.meta.page - 1) * limit);
    }

    // handle pagination
    const total_page = Math.ceil(data.meta.total / limit);
    let page_list = [];
    const diff = total_page - data.meta.page;
    if (diff >= 5) {
      page_list = [data.meta.page, data.meta.page+1, "...", total_page-1, total_page];
    } else {
      if (total_page > 5) {
        for (let i=total_page-4; i<=total_page; i++) {
          page_list.push(i);
        }
      } else {
        for (let i=1; i<=total_page; i++) {
          page_list.push(i);
        }
      }
    }
    
    Object.assign(data.meta, { start, last_row, page_list, total_page })
    state.visitorMessages = data;
    return;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}