import config from "@/ConfigProvider";
const CONTRACTPAL_API = config.value("CONTRACTPAL_API");
import axios from "axios";

export const GET_RESULTS = "GET_RESULTS";
export const SET_RESULTS = "SET_RESULTS";
export const SUBMIT_CONTRACTPAL = "SUBMIT_CONTRACTPAL";

const state = {
  results: [],
};

const getters = {
  currentResults(state) {
    return state.results;
  },
};

const actions = {
  [SUBMIT_CONTRACTPAL](context, params) {
    console.log(SUBMIT_CONTRACTPAL, params);
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", params?.file);
      formData.append("rule", params?.rule);
      formData.append("cache_id", params?.cache_id);
      axios
        .post(`${CONTRACTPAL_API}/contract`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          context.commit(SET_RESULTS, res.data)
          resolve(res.data);
        })
        .catch((err) => {
          console.log("FAILURE!!", err);
          reject();
        });
    });
  },
};

const mutations = {
  [SET_RESULTS](state, data) {
    console.log(SET_RESULTS, data);
    state.results = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
