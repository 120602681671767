import dotenv from "dotenv";
dotenv.config();

export default class ConfigProvider {
  static get CONFIG() {
    return {
      API_URL: "$VUE_APP_API_URL",
      FE_URL: "$VUE_APP_FE_URL",
      GETHIRED_API: "$VUE_APP_GETHIRED_API",
      CONTRACTPAL_API: "$VUE_CONTRACTPAL_API",
      CV_BOT_API: "$VUE_APP_CV_BOT_API",
      WEBCV_URL: "$VUE_APP_WEBCV_URL",
      WEBCV_URL_LABEL: "$VUE_APP_WEBCV_URL_LABEL",
      HARDCODE_TOKEN: "$VUE_APP_HARDCODE_TOKEN",
      CV_URL: "$VUE_APP_CV_URL"
    };
  }

  static value(name) {
    if (!(name in this.CONFIG)) {
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      return;
    }

    if (value.startsWith("$VUE_APP_")) {
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        return;
      }
    } else {
      return value;
    }
  }
}
