import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Chakra, { CThemeProvider, CReset } from '@chakra-ui/vue'
import customTheme from './custom-theme.js'
import ApiService from "@/store/api.service";
import { VERIFY_AUTH } from "@/store/auth.module";
import "@/plugins/vue-meta"

Vue.use(Chakra, {
  extendTheme: customTheme,
})

Vue.config.productionTip = false

ApiService.init();
router.beforeEach(async (to, from, next) => {
  await Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
});

new Vue({
  router,
  store,
  render: h => h(CThemeProvider, [h(CReset), h(App)])
}).$mount('#app')
