<template>
  <c-theme-provider>
    <c-reset />
    <router-view />
  </c-theme-provider>
</template>

<script>

export default {
  name: "App",
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');

html, body {
  font-family: 'Montserrat';
}

</style>
